import type { MetaFunction } from '@remix-run/node'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import CenterLoading from './components/Loading/Password'

export const meta: MetaFunction = () => {
  return [{ title: 'New Remix App' }, { name: 'description', content: 'Welcome to Remix!' }]
}

export default function Index() {
  const { user, userType, company } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    switch (userType) {
      case 'administrator':
        navigate('/admin/dashboard')
        break
      case 'person':
        if (!user || !company) {
          navigate('/companies/mypage')
          break
        }
        navigate(`/companies/${company?.code}/dashboard`)
        break
      default:
        navigate('/login')
    }
  }, [user, userType, company, navigate])

  return <CenterLoading />
}
